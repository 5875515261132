import { Fragment } from "react";
import FormBtnInterface from "./interfaces/FormBtnComponent.interface";

const FormBtnComponent = (props: FormBtnInterface) => {
  return (
    <Fragment>
      <button
        id={props.id}
        type={props.type}
        onClick={props.onClick}
        className={"btn d-flex float-left " + props.cssClass}
        disabled={props.disabled || props.isLoading}
      >
        {props.isLoading && (
          <span
            className="spinner-border spinner-border-sm align-self-center mr-2"
            role="status"
            aria-hidden="true"
            style={{
              verticalAlign: "middle"
            }}
          ></span>
        )}
        {props.text}
      </button>
    </Fragment>
  );
};

export default FormBtnComponent;
