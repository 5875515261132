import { Fragment } from 'react';
import {Animated} from "react-animated-css";
import { sendNewRequest } from 'storage/slices/FormData/NpsFormDataSlice';
import { cleanErrors } from '../../../storage/slices/NpsSurvey/NpsSurveySlice';
import { moveTo } from '../../../storage/slices/Step/StepSlice';
import storage from '../../../storage/Storage';

const cleanError = () => {
  
  storage.dispatch(sendNewRequest(true));
  
  // clean NPS survey error
  storage.dispatch(cleanErrors(true));

  // move to first step
  storage.dispatch(moveTo({ step: 0 }));

}

const ErrorContainer = () => {

  return (
    <Fragment>
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={500} isVisible={true}>
            <h1>Lo sentimos</h1>
            <p>Ocurrió un error al intentar procesar tu solicitud. Por favor, inténtalo más tarde.</p>
            <div className="mt-5">
              <button onClick={ cleanError } className="btn btn-autofact-orange btn-lg text-white">Reintentar</button>
            </div>
        </Animated>
    </Fragment>
  )
}

export default ErrorContainer
