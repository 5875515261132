import axios from 'axios';
import env from 'env';

class HelperService {

  private apiPath: string = env.REACT_APP_REFUND_ENDPOINT;

  async getTransferenceRefundReasons(transactionCode: string) {
    try {
      const apiResponse = await axios.get(this.apiPath + '/reason', {
        params: {
          transactionCode
        }
      });

      return apiResponse.data.data;

    } catch (error) {
      console.log(error);
    }
  }
}

export default new HelperService();