import { Fragment, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { useHistory, useParams} from "react-router-dom";
import { useSelector } from "react-redux";
import { StorageInterface } from "../../../storage/StorageInterface";
import { moveTo } from "../../../storage/slices/Step/StepSlice";
import {
  loadingRequest,
  returnWithErrors,
} from "../../../storage/slices/NpsSurvey/NpsSurveySlice";
import { saveNpsFormData } from "../../../storage/slices/FormData/NpsFormDataSlice";
import Form from "@rjsf/core";
import RandonOptionEnumFieldComponent from "./../../reusables/RandomOptionEnumField/RandonOptionEnumFieldComponent";
import NpsSurveyService from "../../../storage/services/NpsSurvey/NpsSurveyService";
import storage from "../../../storage/Storage";
import FormButton from "../../reusables/FormBtn/FormBtnComponent";
import RefundFormService from "../../../storage/services/RefundForm/RefundFormService";
import { getCountryCode } from "../../../helpers/countryHelper/countryHelper";

const NpsContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const urlParams: any = useParams();
  const history: any = useHistory();
  const countryCode = getCountryCode();

  const customFields = {
    RandomEnumField: RandonOptionEnumFieldComponent,
  };

  const npsFormConfiguration: any = useSelector(
    (state: StorageInterface) => state.npsSurveySlice
  );

  const refundFormConfiguration: any = useSelector(
    (state: StorageInterface) => state.refundFormSlice
  );

  const npsFormData: any = useSelector(
    (state: StorageInterface) => state.npsFormDataSlice
  );

  const refundFormData: any = useSelector(
    (state: StorageInterface) => state.refundFormDataSlice
  );

  const handleSubmit = async (form: any) => {
    setIsLoading(true);
    storage.dispatch(loadingRequest(null));
    try {
      // 1. Store refund form data
      const answersStored = await RefundFormService.storeFormData({
        refundForm: refundFormConfiguration.data,
        formData: refundFormData,
      });

      //Before to send answer to NPS survey verify if coming idProduct 
      //from devolutionDataReport from api method save call
      let productID = npsFormConfiguration.data.productId;
      if (answersStored && answersStored.data?.data?.answer?.devolutionDataReport?.idProduct) {
        productID = answersStored.data?.data?.answer?.devolutionDataReport?.idProduct;
      }

      // 2. Store survey answer
      const documentName = refundFormData.rut ? 'userRut' : 'userCI'
      const formData = form.formData.refundReason ? form.formData : {...form.formData, 'refundReason': 'Informe sin información'}

      await NpsSurveyService.storeNpsSurveyAnswer({
        surveyId: npsFormConfiguration.data.id,
        userId: refundFormData.email, 
        formData: formData,
        metadata: {
          source: 'refunds-webapp',
          transactionCode: refundFormData.transactionCode,
          [documentName]: refundFormData.rut ? refundFormData.rut : refundFormData.ci,
          userId: refundFormData.email,
          productId: productID,
          countryCode: npsFormConfiguration.data.countryCode,
        },
      });
      return history.push("/solicitud-enviada");
    } catch (error) {
      storage.dispatch(returnWithErrors(null)); // TODO: crate a global request handler to dispatch request errors
    }
  };

  const backStep = () => {
    storage.dispatch(moveTo({ step: 0 }));
  };

  const handleChange = (form: any) => {
    storage.dispatch(saveNpsFormData(form.formData));
  };

  useEffect(() => {
    NpsSurveyService.getNpsSurvey(countryCode, urlParams.productSlug);
  }, [countryCode, urlParams.productSlug]);

  return (
    <Fragment>
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        animationInDuration={800}
        animationOutDuration={800}
        isVisible={true}
      >
        {npsFormConfiguration.loaded && (
          <Form
            schema={npsFormConfiguration.data.jsonSchema}
            uiSchema={npsFormConfiguration.data.uiSchema}
            onSubmit={handleSubmit}
            onChange={handleChange}
            formData={npsFormData}
            fields={customFields}
          >
            <small className="text-muted d-block mb-3">
              Los campos marcados con * son obligatorios.
            </small>
            <FormButton
              type="button"
              onClick={backStep}
              cssClass="btn-autofact-secondary btn-lg float-left"
              text="Volver"
            />
            <FormButton
              isLoading= { isLoading }
              type="submit"
              cssClass="btn-autofact-orange btn-lg text-white ml-2"
              text="Enviar solicitud"
              disabled={npsFormConfiguration.loading}
            />
          </Form>
        )}
      </Animated>
    </Fragment>
  );
};

export default NpsContainer;
