import { createRef, Fragment, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Form from "@rjsf/core";
import storage from "../../../storage/Storage";
import RefundFormService from "../../../storage/services/RefundForm/RefundFormService";
import { StorageInterface } from "../../../storage/StorageInterface";
import ReactJsonSchemaHelper from "./../../../helpers/ReactForm/ReactJsonSchemaErrorHelper";
import ReactFormChileanRutFieldComponent from "./../../reusables/ReactFormChileanRut/ReactFormChileanRutFieldComponent";
import { moveTo } from "../../../storage/slices/Step/StepSlice";
import {
  saveRefundFormData,
  saveTransactionCodeByParam,
} from "../../../storage/slices/FormData/RefundFormDataSlice";
import FormButton from "../../reusables/FormBtn/FormBtnComponent";
import { getCountryCode } from "../../../helpers/countryHelper/countryHelper";
import { useLocation } from "react-router-dom";
import ReactFormTransactionCodeField from "components/reusables/ReactFormTransactionCodeField/ReactFormTransactionCodeField";
import {
  loadingRequest,
  returnWithErrors,
} from "../../../storage/slices/NpsSurvey/NpsSurveySlice";
import { customCssClass } from "helpers/Util";
import ENV from "env";

const RefundFormContainer = () => {
  const formRef: any = createRef();
  const [isLoading, setIsLoading] = useState(false);
  const productsShowNpsForm = ENV.PRODUCTS_SHOW_NPS_FORM.split(',');
  const history: any = useHistory();
  const queryParams = useLocation().search;
  const transactionCode = new URLSearchParams(queryParams).get(
    "codigoTransaccion"
  );

  const customFields = {
    ChileanRutField: ReactFormChileanRutFieldComponent,
    TransactionCodeField: ReactFormTransactionCodeField,
  };

  const countryCode = getCountryCode();
  const urlParams: any = useParams();

  const formConfiguration: any = useSelector(
    (state: StorageInterface) => state.refundFormSlice
  );

  const refundFormData: any = useSelector(
    (state: StorageInterface) => state.refundFormDataSlice
  );

  const setTransactionCode = () => {
    if (transactionCode) {
      storage.dispatch(
        saveTransactionCodeByParam({
          transactionCode: transactionCode,
          disableInput: true,
        })
      );
    }
  };

  useEffect(() => {
    setTransactionCode();
    RefundFormService.getFormConfiguration(countryCode, urlParams.productSlug);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, urlParams.productSlug]);

  const handleSubmit = async (form: any) => {
    // enable loading status on form button
    setIsLoading(true);
    
    const hasInvalidInput = Array.from(formRef.current.formElement.elements).some((element: any) => {
      const className = element?.className;
      return className && className.indexOf(customCssClass.is_invalid_input) > -1;
    })
    if (hasInvalidInput) {
      setIsLoading(false);
      return;
    }
    // save local form data
    storage.dispatch(saveRefundFormData(form.formData));

    // if refund form has survey form show "next" button, instead, show "send" form button
    if (productsShowNpsForm.includes(formConfiguration.data.productId)) {
      // disable loading status on form button
      setIsLoading(false);

      // move to next step (nps form)
      storage.dispatch(moveTo({ step: 1 }));
    } else {
      storage.dispatch(loadingRequest(true));

      try {
        // store refund form data
        await RefundFormService.storeFormData({
          refundForm: formConfiguration.data,
          formData: form.formData,
        });

        return history.push("/solicitud-enviada");
      } catch (error) {
        storage.dispatch(returnWithErrors(null));
      }
    }
  };

  return (
    <Fragment>
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOutLeft"
        animationInDuration={800}
        animationOutDuration={200}
        isVisible={true}
      >
        {formConfiguration.loaded && (
          <Form
            ref={formRef}
            schema={formConfiguration.data.jsonSchema}
            uiSchema={formConfiguration.data.uiSchema}
            showErrorList={false}
            formData={refundFormData}
            transformErrors={ReactJsonSchemaHelper.transformErrorsAdapter(
              formConfiguration.data.extraErrors
            )}
            fields={customFields}
            onSubmit={handleSubmit}
            autoComplete="off"
            liveValidate
          >
            <small className="text-muted d-block mb-3">
              Los campos marcados con * son obligatorios.
            </small>

            <FormButton
              isLoading={ isLoading }
              type="submit"
              cssClass="btn-autofact-orange text-white btn-lg"
              text={
                productsShowNpsForm.includes(
                  formConfiguration.data.productId
                )
                  ? "Siguiente"
                  : "Enviar"
              }
            />
          </Form>
        )}
      </Animated>
    </Fragment>
  );
};

export default RefundFormContainer;
