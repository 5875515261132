import { Fragment } from 'react';
import {Animated} from "react-animated-css";
import { getMailAndHomePageByCountry } from '../../../helpers/countryHelper/mailsCountryHelper';

const sendHrefAddress = (homePage: any) => {
  window.open(`https://www.${homePage}`);
}

const InformationPageContainer = (props: any) => {
  const country = props.country ? props.country : 'CL';
  const headerMessage = props.data.headerMessage ? props.data.headerMessage : 'No hemos podido ingresar la solicitud.';
  const bodyMessage = props.data.bodyMessage ? props.data.bodyMessage : 'No se puede solicitar la devolución para este código. Si tienes dudas escribe a ';
  const dataByCountry = getMailAndHomePageByCountry(country);
  
  return (
    <Fragment>
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={500} isVisible={true}>
            <h1>{headerMessage}</h1>
            <p>{bodyMessage} <b>{dataByCountry.mail}</b></p>
            <div className="mt-5">
              <button onClick={ () => sendHrefAddress(dataByCountry.homePage) } className="btn btn-autofact-orange btn-lg text-white">Ir a {dataByCountry.homePage}</button>
            </div>
        </Animated>
    </Fragment>
  )
}

export default InformationPageContainer
