import React, { useEffect, useState } from "react";
import "./scss/ChileanRutFieldComponent.scss";

// @ts-ignore
import chileanRut from "chilean-rut";
import { FieldProps } from "@rjsf/core";
import classNames from "classnames";
import dot from "dot-object";
import { customCssClass } from "helpers/Util";

function ReactFormChileanRutFieldComponent(props: FieldProps) {
  const [isValid, setIsValid] = useState(false);
  const DIGIT_SEPARATOR = "-";

  function onChange(event: any) {
    const minLength = dot.pick("schema.minLength", props) | 8;
    let value =
      typeof event === "string" ? event : dot.pick("target.value", event);
    value = value.slice(0, dot.pick("schema.maxLength", props));
    value = chileanRut.unformat(value, DIGIT_SEPARATOR);
    const rutIsValid = chileanRut.validate(value);
    value = chileanRut.format(value, DIGIT_SEPARATOR);
    const fieldIsValid = (value !== "" && value.length >= minLength) && rutIsValid;
    setIsValid(fieldIsValid);
    props && props.onChange(value);
  }

  const inputClassName: any = {};

  useEffect(() => {
    onChange(props.formData || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  inputClassName[customCssClass.is_invalid_input] = !isValid && props.formData;

  return (
    <div className="form-group field field-string ">
      <label className="control-label" htmlFor={props.idSchema.$id}>
        {props.schema.title}{(props.name === 'rut' && props.hasOwnProperty('required')) ? '*' : null}
      </label>
      <input
        disabled={props.disabled}
        className={`
              chilean-rut form-control
              ${classNames(inputClassName)}
            `}
        id={props.idSchema.$id}
        placeholder={props.placeholder}
        type="text"
        value={props.formData}
        onChange={onChange}
        pattern={props.schema.pattern}
        title="Rut no válido"
        required={(props.name === 'rut' && props.hasOwnProperty('required')) ? true : false}
      />
    </div>
  );
}

export default ReactFormChileanRutFieldComponent;
