import React from 'react';
import './resources/scss/AutofactCustomTheme.scss';
import { BrowserRouter, Route } from 'react-router-dom';
import { Switch, Redirect } from 'react-router';
import storage from './storage/Storage';
import { getCountryCode } from 'helpers/countryHelper/countryHelper';

// components
import HeaderContainer from './components/containers/Header/HeaderContainer';

// page components
import HomePage from './components/routes/HomePage/HomePage';
import { Provider } from 'react-redux';
import ThankYouPage from './components/routes/ThankYouPage/ThankYouPage';
import ErrorContainer from 'components/containers/Error/ErrorContainer';

function App() {
  const countryCode = getCountryCode();
  return (
    <Provider store={ storage }>
      <BrowserRouter>
        <HeaderContainer />
        <Switch>
          <Route exact path="/solicitud-enviada">
            <ThankYouPage countryCode={countryCode}/>
          </Route>
          <Route exact path="/:productSlug">
            <HomePage />
          </Route>
          <Route exact path="/">
            <div className="container py-5">
              <div className="row">
                <div className="col-sm-12 text-center mt-5 p-3">
                  <ErrorContainer />
                </div>
              </div>
            </div>
          </Route>
          <Redirect to="/"/>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;