export function getCountryCode() {
  const domain = window.location.hostname;
  let countrycode = "cl";
  const countries: any = {
    cl: [
      "cl.devoluciones.autofact.local",
      "cl.devoluciones.autofact.qa",
      "devoluciones.staging.autofact.cl",
      "devoluciones.autofact.cl",
      "staging.devoluciones.autofact.cl"
    ],
    pe: [
      "pe.devoluciones.autofact.local",
      "pe.devoluciones.autofact.qa",
      "devoluciones.staging.autofact.pe",
      "devoluciones.autofact.pe",
      "staging.devoluciones.autofact.pe"
    ],
    co: [
      "co.devoluciones.autofact.local",
      "co.devoluciones.autofact.qa",
      "devoluciones.staging.autofact.com.co",
      "devoluciones.autofact.com.co",
      "staging.devoluciones.autofact.com.co"
    ],
    mx: [
      "mx.devoluciones.autofact.local",
      "mx.devoluciones.autofact.qa",
      "devoluciones.staging.autofact.com.mx",
      "devoluciones.autofact.com.mx",
      "staging.devoluciones.autofact.com.mx"
    ]
  };

  Object.keys(countries).forEach(country => {
    if (countries[country].includes(domain)) {
      countrycode = country;
    }
  });

  return countrycode;
}
