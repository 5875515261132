import { useEffect } from 'react';
import { FieldProps } from '@rjsf/core';
import dot from "dot-object";
import { Fragment } from 'react';

/**
 * To use correctly this helper, you should have deffined a property called "Otro" in your Enum field.
 */

function RandonOptionEnumFieldComponent(props: FieldProps){
  const enumOptions = props.schema.enum;
  
  const onChange =  (event: any) => {
    let value = typeof event === "string" ? event : dot.pick("target.value", event);
    value = value.slice(0, dot.pick("schema.maxLength", props));
    props && props.onChange(value);
  }

  const sortOptions = (options: any) => {

    let enumOptionsSort;

    if(options.includes('Otro')){
      enumOptionsSort = [...options]?.slice().sort(() => Math.random() - 0.5)
      enumOptionsSort.push(enumOptionsSort.splice(enumOptionsSort.indexOf('Otro'),1)[0]);
    }else{
      enumOptionsSort = options?.slice().sort(() => Math.random() - 0.5)
    }

    return enumOptionsSort;
  }

  const optionSorted = sortOptions(enumOptions);

  useEffect(() => {
    onChange(props.formData || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <Fragment>

      <label className="control-label" htmlFor={props.idSchema.$id}>
        {props.schema.title}{ props.required ? '*' : null }
      </label>

      <select
        id={props.idSchema.$id}
        className='form-control'
        placeholder={props.placeholder}
        value={props.formData}
        onChange={onChange}
        required={props.required ? true : false}
      >

        { props.uiSchema.hasOwnProperty('ui:placeholder') && <option value=''>- Selecciona una opción -</option> }

        { 
          // @ts-ignore
          optionSorted?.map((option, index) => <option key={index} value={option}>{option}</option>) 
        }

      </select>

    </Fragment>
    
  );
}

export default RandonOptionEnumFieldComponent;