import { createSlice, Slice as SliceInterface } from '@reduxjs/toolkit';

const RefundFormDataSlice: SliceInterface = createSlice({
    name: 'REFUND_FORM_DATA',
    initialState: {
        transactionCode: null,
        disableInput: false,
        isValidatingTransactionCode: false,
        isValidTransactionCode: true
    },
    reducers: {
        saveRefundFormData(state, action){
            return {
                ...action.payload,
            };
        },
        saveTransactionCode(state, action){
            return{
                ...state,
                ...action.payload,
            }
        },
        saveTransactionCodeByParam(state, action){
            return{
                ...state,
                ...action.payload
            }
        },
        validatingTransactionCode(state, action) {
            return { ...state, ...action.payload }
        }
    }
});

export const {
    saveRefundFormData,
    saveTransactionCode,
    saveTransactionCodeByParam,
    validatingTransactionCode
} = RefundFormDataSlice.actions;

export default RefundFormDataSlice;