import { createSlice, Slice as SliceInterface } from '@reduxjs/toolkit';

const NpsFormDataSlice: SliceInterface = createSlice({
    name: 'NPS_FORM_DATA',
    initialState: {
        refundReason: '',
        sendNewRequest: true,
        loaded: false,
        error: false
    },
    reducers: {
        saveNpsFormData(state, action){
            return {
                ...action.payload,
            };
        },
        setRefundFormReason(state, action){
            return{
                ...state,
                ...action.payload
            };
        },
        sendNewRequest(state, action){
            return {
                ...state,
                sendNewRequest: action.payload
            }
        },
        setError(state, action){
            return {
                ...state,
                error: action.payload
            }
        }
    }
});

export const {
    saveNpsFormData,
    setRefundFormReason,
    sendNewRequest,
    setError
} = NpsFormDataSlice.actions;

export default NpsFormDataSlice;