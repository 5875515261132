import ENV from 'env';

// Refund Reasons Map
export interface refundReasonsMapInterface {
  limitacion_dominio?: string;
  cav_sin_informacion?: string;
  encargo_robo?: string;
  limitacion_empresa?: string;
}

interface productTypeInterface {
  transference: Array<number>,
  report: Array<number>
}

interface customCssClassInterface {
  is_invalid_input: string
}

export const refundReasonsMap: refundReasonsMapInterface = {
  limitacion_dominio: 'Solicité transferencia para un vehículo con limitaciones al dominio (prenda, embargo, etc)',
  cav_sin_informacion: 'Solicité transferencia para un vehículo que no registra Certificado de Anotaciones Vigentes',
  encargo_robo: 'Solicité transferencia para un vehículo con encargo por robo',
  limitacion_empresa: 'Solicité transfierencia para una empresa'
}

export const productType: productTypeInterface = {
  transference: ENV.TRANSFERENCE_PRODUCT_CODES.split(',') ,
  report: ENV.REPORT_PRODUCT_CODES.split(',') 
}

export const customCssClass: customCssClassInterface = {
  is_invalid_input: 'is-invalid'
}
