import axios from 'axios';
import { 
  addRefundFormDataConfiguration, 
  returnWithErrors,
  returnErrorExistDevolution } from '../../slices/RefundForm/RefundFormSlice';
import storage from '../../Storage';
import env from 'env';

class RefundFormService {
  
  // Should receive two parameters as query string (countryCode & productSlug)
  private apiPath: string = env.REACT_APP_REFUND_ENDPOINT;

  async getFormConfiguration(countryCode: string, productSlug: string){
    try {  
      const result = await axios.get(this.apiPath, {
        params: {
          countryCode,
          productSlug
        }
      });
      storage.dispatch(addRefundFormDataConfiguration(result.data));
    } catch (error) {
      storage.dispatch(returnWithErrors(null)); 
    }
  
  }
  
  async storeFormData(data: any){
    try {
      const result = await axios.post(this.apiPath, data);
      return result;
    } catch (error: any) {
      if (error.response && error.response.data) {
        // Request made and server responded
        const dataMsgSchema = error.response.data.data;
        storage.dispatch(returnErrorExistDevolution(dataMsgSchema));
      }
      throw new Error(error);
    }
  }
}

export default new RefundFormService();