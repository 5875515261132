import { Fragment } from 'react';
import {Animated} from "react-animated-css";
import './scss/SpinnerLoadingComponent.scss';
import SpinnerLoadingComponentInterface from './interfaces/SpinerLoadingComponent.interface';

const SpinnerLoadingComponent = (props: SpinnerLoadingComponentInterface) => {

  const backgroundColor = props.color ? props.color :'#155893';

  return (
    <Fragment>
      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={200} animationOutDuration={200} isVisible={true}>
      <div className="spinner">
        <div className="double-bounce1" style={{ backgroundColor }}></div>
        <div className="double-bounce2" style={{ backgroundColor }}></div>
      </div>
      </Animated>
    </Fragment>
  )
}

export default SpinnerLoadingComponent