import React, { Fragment } from 'react';
import { getMailAndHomePageByCountry } from '../../../helpers/countryHelper/mailsCountryHelper';
import './scss/ThankYouPage.scss';


const ThankYouPage = (props: any) => {
  const countryCode = props.countryCode || 'CL';
  const dataByCountry = getMailAndHomePageByCountry(countryCode);
  return (
    <Fragment>
      <div className="vh-80 d-flex align-items-center">
        <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="h1 text-bold">Hemos recibido tu solicitud</h1>
                <p>Te contactaremos pronto para continuar con el proceso de devolución</p>
                <a href={`https://www.${dataByCountry.homePage}`} className="btn btn-autofact-orange px-3 mt-3 text-white">
                  Ir a Autofact.{countryCode.toLowerCase()}
                </a>
              </div>
            </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ThankYouPage
