import storage from '../../../storage/Storage';
import { FieldProps } from "@rjsf/core";
import { saveTransactionCode, validatingTransactionCode } from "storage/slices/FormData/RefundFormDataSlice";
import { useSelector } from "react-redux";
import { StorageInterface } from '../../../storage/StorageInterface';
import TransferenceService from 'storage/services/RefundForm/TransferenceService';

const transactionCodeRegExp = /^(RTR)([\w-]){6,12}$/;

function ReactFormTransactionCodeField(props: FieldProps) {

  const refundFormData: any = useSelector(
    (state: StorageInterface) => state.refundFormDataSlice
  );

  const handleChange = (event: any) => {
    storage.dispatch(saveTransactionCode({
      transactionCode: event.target.value,
      disableInput: false,
      isValidTransactionCode: true
    }));
  }
  const handleOnBlur = async (event: any) => {
    const fieldValue: string = event.target.value;
    if (transactionCodeRegExp.test(fieldValue)) {
      storage.dispatch(validatingTransactionCode({ isValidatingTransactionCode: true }));
      const isValidTransactionCode = await TransferenceService.isValidTransactionCode(fieldValue);
      storage.dispatch(validatingTransactionCode({ isValidatingTransactionCode: false, isValidTransactionCode }));
    }
  }
 
  return (
    <div className={refundFormData.disableInput ? 'd-none': ''}>
        <label className="control-label" htmlFor={props.idSchema.$id}>
          {props.schema.title}{(props.name === 'transactionCode' && props.hasOwnProperty('required')) ? <span className="required">*</span> : null} 
        </label>
        <div className="d-flex align-items-center">
          <input
            type="text"
            className="form-control"
            id={props.idSchema.$id}
            value={props.formData}
            required={props.hasOwnProperty('required') ? true : false}
            onChange={handleChange}
            onBlur={handleOnBlur}
          />
          {
            refundFormData.isValidatingTransactionCode && <span className="spinner-grow spinner-grow-sm input-spinner" role="status" aria-hidden="true"></span>
          }
        </div>      
        {
          !refundFormData.isValidTransactionCode && <ul className="error-detail"><li className="text-danger">Código inválido</li></ul>
        }  
      </div>
  );
}

export default ReactFormTransactionCodeField;
