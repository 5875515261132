import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ENV from "./env";
import TagManager from "react-gtm-module";

//GTM
const gtmId = ENV.TagManagerCodesConfig[ENV.countryCode];
TagManager.initialize({ gtmId });

ReactDOM.render(<App />,document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
