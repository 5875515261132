import React, { Fragment } from 'react';
import './scss/HeaderContainer.scss';

const HeaderContainer = () => {
  return (
    <Fragment>
      <nav className="navbar navbar-autofact-primary bg-autofact-primary">
        <div className="container">
          <a className="navbar-brand" href="https://autofact.cl">
            <img src="https://www.autofact.cl/media/site/img/logo-autofact-blanco.png" width="140" alt="Autofact logo" loading="lazy" />
          </a>
        </div>
      </nav>
    </Fragment>
  )
}

export default HeaderContainer