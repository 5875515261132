import {getCountryCode} from "./helpers/countryHelper/countryHelper";

const configs:any = require("./config.json");

const ENV = {
    ...configs,
    countryCode: getCountryCode(),
    TagManagerCodesConfig: {
        cl: configs.REACT_APP_GMT_CL || "GTM-NM62MBM",
        pe: configs.REACT_APP_GMT_PE || "GTM-55M7W2W",
        co: configs.REACT_APP_GMT_CO || "GTM-T24MSVX",
        mx: configs.REACT_APP_GMT_MX || "GTM-PZP9W6L"
    }
}

export default ENV;