import { createSlice, Slice as SliceInterface } from '@reduxjs/toolkit';

const NpsSurveySlice: SliceInterface = createSlice({
    name: 'NPS_SURVEY',
    initialState: {
        jsonSchema: {},
        uiSchema: {},
        extraErrors: {},
        loaded: false,
        error: false,
        loading: false,
        loadingRequest: false
    },
    reducers: {
        addNpsSurveyConfiguration(state, action){
            return {
                ...action.payload,
                loaded: true,
            };
        },
        returnWithErrors(state, action){ 
            return {
                ...action.payload,
                loaded: true,
                error: true
            }
        },
        loadingRequest(state, action){
          return {
            ...action.payload,
            loadingRequest: true
          }
        },
        cleanErrors(state, action){
            return {
                ...action.payload,
                error:false
            }
        }
    }
});

export const {
    addNpsSurveyConfiguration,
    returnWithErrors,
    loadingRequest,
    cleanErrors
} = NpsSurveySlice.actions;

export default NpsSurveySlice;