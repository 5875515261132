import { AjvError } from 'react-jsonschema-form';
import { uniqBy } from 'lodash';

class ReactJsonSchemaErrorHelper {
  transformErrorsInArrayFieldAdapter(errorSchema: any) {
    return function transformErrorsAdapter(errors: Array<AjvError>) {
      let finalErrors: Array<AjvError> = [];
      errors.forEach(error => {
        const errorKey = error.property.split(".")[2];
        if (errorKey && errorSchema[errorKey]) {
          error.message = errorSchema[errorKey][error.name];
          finalErrors.push(error);
        }
      });

      finalErrors = finalErrors.filter(error => error);
      return uniqBy(
        finalErrors.filter(error => error),
        error => {
          return error.property + error.name;
        }
      );
    };
  }

  transformErrorsAdapter(errorSchema: any) {
    return function transformErrorsAdapter(errors: Array<AjvError>) {
      let finalErrors: Array<AjvError> = [];
      errors.forEach(error => {
        const errorKey = error.property.replace(".", "");
        if (errorKey && errorSchema[errorKey]) {
          error.message = errorSchema[errorKey][error.name];
          finalErrors.push(error);
        }
      });

      return errors;
    };
  }
}

export default new ReactJsonSchemaErrorHelper();
