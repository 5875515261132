import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import RefundFormSlice from './slices/RefundForm/RefundFormSlice';
import NpsSurveySlice from './slices/NpsSurvey/NpsSurveySlice';
import StepSlice from './slices/Step/StepSlice';
import RefundFormDataSlice from './slices/FormData/RefundFormDataSlice';
import NpsFormDataSlice from './slices/FormData/NpsFormDataSlice';
import RequestErrorHandler from './slices/RequestErrorHandler/RequestErrorHandlerSlice';

const combinedReducers = combineReducers({
  refundFormSlice: RefundFormSlice.reducer,
  npsSurveySlice: NpsSurveySlice.reducer,
  stepSlice: StepSlice.reducer,
  refundFormDataSlice: RefundFormDataSlice.reducer,
  npsFormDataSlice: NpsFormDataSlice.reducer,
  requestErrorHandler: RequestErrorHandler.reducer
});

const storage = configureStore({
  reducer: combinedReducers
});

export default storage;