import { Fragment, useEffect } from 'react'

import RefundFormContainer from '../../containers/RefundForm/RefundFormContainer';
import NpsContainer from '../../containers/NpsForm/NpsContainer';
import {useSelector} from "react-redux";
import {StorageInterface} from "../../../storage/StorageInterface";
import SpinnerLoading from '../../reusables/SpinnerLoading/SpinnerLoadingComponent';
import ErrorContainer from '../../containers/Error/ErrorContainer';
import InformationPageContainer from '../../containers/InformationPage/InformationPageContainer';
import { useLocation } from 'react-router-dom';
import HelperService from 'storage/services/HelperService';
import storage from 'storage/Storage';
import { setError, setRefundFormReason } from 'storage/slices/FormData/NpsFormDataSlice';
import { productType, refundReasonsMap } from 'helpers/Util';
import { getCountryCode } from 'helpers/countryHelper/countryHelper';
import ENV from 'env';

const HomePage = () => {

  const productsShowNpsForm = ENV.PRODUCTS_SHOW_NPS_FORM.split(',') ;
  const queryParams = useLocation().search;
  const transactionCode = new URLSearchParams(queryParams).get('codigoTransaccion');
  const countryCode = getCountryCode();

  // step state
  const actualForm: any = useSelector(
    (state: StorageInterface) => state.stepSlice
  );

  // refund form state
  const refundForm: any = useSelector(
    (state: StorageInterface) => state.refundFormSlice
  );
  
  // nps form state
  const npsForm: any = useSelector(
    (state: StorageInterface) => state.npsSurveySlice
  );
  
  // nps form state
  const refundReason: any = useSelector(
    (state: StorageInterface) => state.npsFormDataSlice
  );

  // if url params has transactionCode 
  const setNPSSurveyData = async () => {
    // get refund reasons and set into NPS form only if is a transference product
    if (transactionCode && refundReason.sendNewRequest && refundForm.loaded && productType?.transference.includes(refundForm.data?.productId)) {
      try {
        const serviceResponse = await HelperService.getTransferenceRefundReasons(transactionCode);
        const refundReasonChoosed: string =  serviceResponse.refundReasons[0];
        const refundReasonMap: any = refundReasonsMap;
        const alternativeToSet = refundReasonMap[refundReasonChoosed];
        
        storage.dispatch(setRefundFormReason({
          refundReason: alternativeToSet,
          sendNewRequest: false
        }));
        
      } catch (error) {
        console.error(error);
        storage.dispatch(setError(true));
      }
    }
  }

  useEffect(() => {
    setNPSSurveyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundForm.loaded]);

  return (
    <Fragment>

      { 
        // show spinner while loading (API request)
        (!refundForm.loaded || (actualForm.step === 1 && !npsForm.loaded)) && <SpinnerLoading />
      }

      <div className={ (refundForm.loaded) ? 'container py-5': 'd-none' }>  
        <div className="row">
          {
            // Show error if API requests fails (refund form, nps survey or (if correspond) refund reason)
            (refundForm.error || npsForm.error || (transactionCode && refundReason.error))
            ?
            <Fragment>
              <div className="col-sm-12 text-center mt-5 p-3">
                { !refundForm.errorExistDevolution ? <ErrorContainer /> : <InformationPageContainer country={countryCode} data={refundForm}/> }
              </div>
            </Fragment>
            : 
            <Fragment>
              <div className="col-sm-12 offset-lg-3 col-lg-6">
                <div className={(actualForm.step === 0) ? 'd-inline': 'd-none'}>
                  <RefundFormContainer />
                </div>
                <div className={(actualForm.step === 1) ? 'd-inline': 'd-none'}>
                  
                  {
                    // render NpsContainer only if refund form has a NPS form
                    (refundForm.loaded && productsShowNpsForm.includes(
                      refundForm.data.productId
                    )) && 
                    <NpsContainer />
                  }
                </div>
              </div>
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default HomePage
