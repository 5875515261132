import { createSlice, Slice as SliceInterface } from '@reduxjs/toolkit';

const RequestErrorHandler: SliceInterface = createSlice({
  name: 'REQUEST_ERROR_HANDLER',
  initialState: {
    error: false,
  },
  reducers: {
    requestError(state, action){
      return { 
        ...action.payload,
        error: true
      };
    },
    cleanErrors(state, action){
      return {
        ...action.payload,
        error: false
      }
    }
  }
});

export const {
  requestError,
  cleanErrors
} = RequestErrorHandler.actions;

export default RequestErrorHandler;