import { createSlice, Slice as SliceInterface } from '@reduxjs/toolkit';

const StepSlices: SliceInterface = createSlice({
  name: 'STEP',
  initialState: {
    step: 0
  },
  reducers: {
    moveTo(state, action){
      return { 
        ...action.payload,
      };
    },
  }
});

export const {
    moveTo
} = StepSlices.actions;

export default StepSlices;