import { upperCase } from "lodash";

export function getMailAndHomePageByCountry(country: string) {
  const mails: any = [
    {country: 'CL', mail: 'ayuda@autofact.cl', homePage: 'autofact.cl'},
    {country: 'PE', mail: 'ayuda@autofact.com.pe', homePage: 'autofact.pe'},
    {country: 'CO', mail: 'ayuda@autofact.com.co', homePage: 'autofact.com.co'},
    {country: 'MX', mail: 'ayuda@autofact.com.mx', homePage: 'autofact.com.mx'}
  ];

  const filtered = mails.filter(function(element: any) {
    return element.country === upperCase(country);
  });

  return filtered[0];
}