import axios from 'axios';
import env from 'env';

class TransferenceService {
  private baseUrl: string = env.REACT_APP_RETAIL_API_TRF;

  async isValidTransactionCode(transactionCode: string) {
    try {
      const { data } = await axios.get(`${this.baseUrl}transference/check-if-exists/${transactionCode}`);
      if (data.data === transactionCode) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new TransferenceService();
