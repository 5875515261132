import axios from 'axios';
import { addNpsSurveyConfiguration, returnWithErrors } from '../../slices/NpsSurvey/NpsSurveySlice';
import storage from '../../Storage';
import env from 'env';

class NpsSurveyService {

    /*
    * Connect to API survey
    * All survey stored in API should be searched as "nps-refund-SurveySlug". Example: "nps-refund-transferencia"
    * Survey API documentation can be found here: https://autofact.atlassian.net/wiki/spaces/RETAIL/pages/739901610/retail-api-surveys
    *
    */

    private SURVEY_ENDPOINT = env.REACT_APP_SURVEY_ENDPOINT;
    private SURVEY_ANSWER_ENDPOINT = env.REACT_APP_SURVEY_ANSWER_ENDPOINT;

    async getNpsSurvey(countryCode: string, productSlug: string) {
        let result: any;

        try {
            const surveySlug = `refund-${productSlug}`;
            result = await axios.get(this.SURVEY_ENDPOINT, {
                params: {
                    countryCode: countryCode,
                    slug: surveySlug
                }
            });

            storage.dispatch(addNpsSurveyConfiguration(result.data));
        } catch (error) {
            storage.dispatch(returnWithErrors(null)); 
        }
    }

    async storeNpsSurveyAnswer(data: any) {
        let result;

        try {
            result = await axios.post(this.SURVEY_ANSWER_ENDPOINT, data);
        } catch (error: any) {
            throw new Error(error);
        }

        return result;
    }
}

export default new NpsSurveyService();